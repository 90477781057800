import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeaderComponent from "../components/page-header";
// import BgImage from "../images/misc/bg-callcenter.jpg"
import ImgCallCenter from "../images/pages/call-center.jpg"
import ImgServices from "../images/pages/services.jpg"
import ImgItSolutions from "../images/pages/it-solutions.jpg"
import ImgBackOffice from "../images/pages/back-office.jpg"
import { Container } from "react-bootstrap";

const ServicesPage = () => (
    <Layout >
        <div style={{
            backgroundImage: `url(${ImgServices})` ,
            backgroundPosition:`center center`,
            backgroundSize:`cover`
        }}>


            <PageHeaderComponent name="Services" desc="" />
        </div>
        <SEO title="Services" />
        <section className="page-section">
            <Container>

                <div className="d-flex mb-5">
                    <div className="col-4 mr-3">
                        <Link to="/services/call-center">
                            <img src={ImgCallCenter} className="img-fluid"/>
                        </Link>
                    </div>
                    <div >
                        <Link to="/services/call-center">
                            <h1 className="service-heading mt-0 mb-3 text-dark">Call Center</h1>
                            <p className="text-muted mb-0 text-large">We provide a full service call center solution for your business with our agents handling all points of contact. We work closely with you to ensure that your business culture and needs are fully represented in all areas during the onboarding process and setup.</p>
                        </Link>
                    </div>
                </div>
            </Container>
        </section>


        <section className="bg-light page-section">
            <Container>

                <div className="d-flex mb-5">
                    <div className="col-4 mr-3">
                        <Link to="/services/it-solutions">
                            <img src={ImgItSolutions} className="img-fluid"/>
                        </Link>
                    </div>
                    <div >
                        <Link to="/services/it-solutions">
                            <h1 className="service-heading mt-0 mb-3 text-dark">IT Solutions</h1>
                            <p className="text-muted mb-0 text-large">We offer a full range of IT services to support your business infrastructure outside of your data center, too. From networking to hardware to applications, our team of certified experts will monitor, manage and maintain your IT environment.
                            </p>
                        </Link>
                    </div>
                </div>
            </Container>
        </section>


        <section className="page-section">
            <Container>

                <div className="d-flex mb-5">
                    <div className="col-4 mr-3">
                        <Link to="/services/back-office">
                            <img src={ImgBackOffice} className="img-fluid"/>
                        </Link>
                    </div>
                    <div >
                        <Link to="/services/back-office">
                            <h1 className="service-heading mt-0 mb-3 text-dark">Back-office</h1>
                            <p className="text-muted mb-0 text-large">Back office outsourcing provides a vital link to businesses to provide them with more technical and robust manpower that will enable small businesses to immediately scale its operations without additional capital expenditures.
                            </p>
                        </Link>
                    </div>
                </div>
            </Container>
        </section>

    </Layout>
)

export default ServicesPage
